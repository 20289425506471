<template>
  <div class="login-page">
    <Modal shown unclosable>
      <h2 slot="header" class="offset-3 col">{{ $t("auth.registration3") }}</h2>
      <div slot="body">
        <form @submit.prevent="submitForm">
          <div class="row pt-4 py-3">
            <div class="col-3 align-self-center form-label">
              {{ $t("auth.login") }}
            </div>
            <div class="col-9">
              <input
                type="text"
                v-model="login"
                autocomplete="username"
                :class="{ 'form-input--is-invalid': $v.login.$error }"
                class="form-input"
              />
            </div>
          </div>
          <div class="row py-3">
            <div class="col-3 align-self-center form-label">Email</div>
            <div class="col-9">
              <input
                type="text"
                v-model="email"
                autocomplete="email"
                :class="{ 'form-input--is-invalid': $v.email.$error }"
                class="form-input"
              />
            </div>
          </div>
          <div class="row py-3">
            <div class="col-3 align-self-center form-label">
              {{ $t("auth.password") }}
            </div>
            <div class="col-9">
              <input
                type="password"
                v-model="password"
                autocomplete="new-password"
                :class="{ 'form-input--is-invalid': $v.password.$error }"
                class="form-input"
              />
            </div>
          </div>
          <div class="row py-3">
            <div class="col-3 align-self-center form-label">
              {{ $t("auth.phoneNumber") }}
            </div>
            <div class="col-9">
              <input
                type="tel"
                v-model="phone"
                placeholder="+7"
                :class="{ 'form-input--is-invalid': $v.phone.$error }"
                class="form-input"
              />
            </div>
          </div>
          <template v-if="isVisibleAgree">
            <div class="row pt-3">
              <div class="offset-3 col-9">
                <Checkbox :val.sync="rules_accepted">
                  {{ $t("common.iAgree") }}
                  <a
                    target="_blank"
                    href="https://utraff.com/static/pdf/rules.pdf"
                  >
                    {{ $t("main.termsCooperation") }}
                  </a>
                </Checkbox>
              </div>
            </div>
            <div class="row py-1">
              <div class="offset-3 col-9">
                <Checkbox :val.sync="offer_accepted">
                  {{ $t("common.iAgree") }}
                  <a target="_blank" :href="offerLink">
                    {{ $t("main.contractOffer") }}
                  </a>
                </Checkbox>
              </div>
            </div>
          </template>
          <div class="row pt-4">
            <div class="offset-3 col-9">
              <div class="d-flex">
                <button
                  type="submit"
                  :disabled="isDisabledBtn"
                  class="form-btn"
                >
                  {{ $t("auth.registration") }}
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-3 pb-3">
            <section class="col offset-3 form-error-list">
              <div v-if="submitted && login.length === 0">
                {{ $t("auth.enterLogin") }}
              </div>
              <div
                v-else-if="
                  (submitted && $v.login.$anyError) ||
                  formErr.hasOwnProperty('login')
                "
              >
                {{ $t("notification.loginAlreadyUse") }}
              </div>
              <div v-if="submitted && email.length === 0">
                {{ $t("auth.enterEmail") }}
              </div>
              <div v-else-if="$v.email.$anyError">
                {{ $t("notification.incorrectEmail") }}
              </div>
              <div v-else-if="submitted && formErr.hasOwnProperty('email')">
                {{ $t("notification.emailAlreadyUse") }}
              </div>
              <div
                v-if="
                  (submitted && $v.password.$anyError) ||
                  formErr.hasOwnProperty('password')
                "
              >
                {{ $t("validations.minLengthPasswordX", [6]) }}
              </div>
              <div
                v-if="
                  (submitted && $v.phone.$anyError) ||
                  formErr.hasOwnProperty('phone')
                "
              >
                {{
                  $t("notification.phoneNumberFormatX", ["+7 234 567 89 00"])
                }}
              </div>
            </section>
          </div>
        </form>
      </div>
      <div slot="footer" class="offset-3 col">
        {{ $t("auth.alreadyRegistered") }}
        <router-link
          :to="{ name: 'login', query: $route.query }"
          class="col align-self-center modal-link"
        >
          {{ $t("auth.enter") }}
        </router-link>
      </div>
    </Modal>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import Modal from '@/components/Modal.vue';
import Checkbox from '@/components/decorative/Checkbox.vue';

const phoneNumber = value => /^(([+]?\d{1,3}|\d{1,3})?\s*([(]{1}\s*[\d]{3}[)]{1}|\d{3})\s*(\d{3}\s*[-]?\s*\d{2}\s*[-]?\s*\d{2}))?$/.test(
  value,
);
const emailValidator = value => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
  value,
);

export default {
  name: 'Register',
  components: {
    Modal,
    Checkbox,
  },
  data() {
    return {
      login: '',
      email: '',
      password: '',
      phone: '',
      submitted: false,
      formErr: {},
      rules_accepted: false,
      offer_accepted: false,
    };
  },
  validations: {
    login: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email: emailValidator,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    phone: {
      phoneNumber,
    },
    // rules_accepted: {
    //   truly: val => val,
    // },
    offer_accepted: {
      truly(val) {
        if (process.env.VUE_APP_MODE_NAME !== 'production') return true;
        return val;
      },
    },
  },
  computed: {
    offerLink() {
      return 'https://utraff.com/static/pdf/offer.pdf';
    },
    isVisibleAgree() {
      return process.env.VUE_APP_MODE_NAME === 'production';
    },
    isDisabledBtn() {
      if (this.isVisibleAgree) {
        return !(this.offer_accepted && this.rules_accepted);
      }

      return false;
    },
  },
  methods: {
    submitForm() {
      this.submitted = true;
      this.formErr = {};

      this.$v.$touch();

      if (this.$v.$invalid) return;

      const loading = this.$loading({
        lock: true,
        background: 'rgba(255, 255, 255, 0.7)',
      });

      const {
        login, password, email, phone,
      } = this;

      this.$store
        .dispatch('authRequest', {
          action: 'registration',
          user: {
            login,
            password,
            email,
            phone,
          },
        })
        .then(() => {
          if (this.$route.query.target) {
            this.$metrika.reachGoal(this.$route.query.target);
          }
          this.$router.push('/');
        })
        .catch((error) => {
          this.formErr = error.data.error.message;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>
